import React, { useState } from 'react';
import * as styled from 'assets/styles';
import { routesConfig } from 'helper/config';
import { ApiGetByUserId } from 'helper/API';
import moment from 'moment/moment';
import { Auth } from 'helper/Auth';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { InVoicePdf } from 'components/commonComponents';
import { useQuery } from 'react-query';

const orderStatuses = [
  'all',
  'pending',
  'confirmed',
  'processing',
  'shipped',
  'delivered',
  'canceled',
  'return',
];

const fetchOrdersByUserId = async (user_id) => {
  const response = await ApiGetByUserId('/order/user', user_id);
  return response?.data;
};

const useOrderData = () => {
  const [orderStatus, setOrderStatus] = useState('all');

  const user_id = Auth?.getUserId();

  const { data: orderStatusData, isLoading } = useQuery(
    ['orders', user_id],
    () => fetchOrdersByUserId(user_id),
    {
      staleTime: 2000 * 60,
      cacheTime: 2000 * 60,
      enabled: !!user_id, // Only run the query if user_id is truthy
    }
  );

  const handleOrderStatusChange = (orderStatus) => {
    setOrderStatus(orderStatus);
  };

  const orderFilterData = orderStatusData?.filter((status) => {
    if (orderStatus === 'all') return true;
    else return status?.order_status === orderStatus;
  });

  const orderStatusValue = orderStatuses?.map((item) => {
    return (
      <li key={item} className="my_order_main_details_tab_li">
        <button
          onClick={() => handleOrderStatusChange(item)}
          className={`my_order_main_details_tab_button capitalize ${
            orderStatus === item ? 'active' : ''
          }`}
        >
          {item}
        </button>
      </li>
    );
  });

  const orderStatusList = orderFilterData?.reverse()?.map((item) => {
    const orderItems = [...item.items]?.reverse();
    const orderDate = moment(item?.created_at).format('DD MMMM YYYY');
    return (
      <>
        <li
          key={item?._id}
          className="my_order_main_details_status_details_li mb-4 last:mb-0"
        >
          <styled.DashboardDetailsBorder padding={'20px'}>
            <div className="my_order_main_details_status_details_inner_div">
              <div className="my_order_main_details_status_head">
                <div className="my_order_main_details_status_head_id gap-6">
                  <div className="my_order_main_details_status_head_id_para">
                    <styled.Para16Tag $weight="500" color="#2E3233">
                      Order ID :
                    </styled.Para16Tag>
                    <styled.Para16Tag $weight="600">
                      {item?._id}
                    </styled.Para16Tag>
                  </div>
                  <div className="my_order_main_details_status_head_id_para">
                    <styled.Para16Tag $weight="500" color="#2E3233">
                      Order Date :
                    </styled.Para16Tag>
                    <styled.Para16Tag $weight="600">
                      {orderDate}
                    </styled.Para16Tag>
                  </div>
                </div>
                <styled.ColorBoxDiv $bg_color="#fb85000f">
                  <styled.Para16Tag $weight="500" color="#FB8500">
                    {item?.order_status.charAt(0).toUpperCase() +
                      item?.order_status.slice(1)}
                  </styled.Para16Tag>
                </styled.ColorBoxDiv>
              </div>
              <styled.BorderDivider $m_bottom={20} m_top={20} />
              {orderItems?.map((x, index) => {
                let product = {
                  name: '',
                  lenseName: '',
                  image: '',
                  color: '',
                  size: '',
                  model: '',
                  sku: '',
                  price: x?.product_price || x?.accessory_price || 0,
                };
                if (Object.hasOwnProperty.call(x, 'product_image')) {
                  product.image = x?.product_image;
                  product.name = x?.product_name;
                  product.size = x?.product_size_name;
                  product.color = x?.product_color;
                  product.sku = x?.product_sku_no;
                  if (x?.lenses?.lense_id) {
                    product.lenseName =
                      product.lenseName +
                      x?.lenses?.lense_id?.prescription_type;
                    product.price = product.price + x?.lenses?.lens_price;
                  }
                  if (x?.lenses?.singlevision_id) {
                    product.lenseName =
                      product.lenseName +
                      ' + ' +
                      x?.lenses?.singlevision_id?.single_vision_type;
                    product.price =
                      product.price + x?.lenses?.singlevision_price;
                  }
                  if (x?.lenses?.lense_type_id) {
                    product.lenseName =
                      product.lenseName +
                      ' + ' +
                      x?.lenses?.lense_type_id?.lenses_type;
                    product.price = product.price + x?.lenses?.lens_type_price;
                  }
                  if (x?.lenses?.lens_type_color_id) {
                    product.lenseName =
                      product.lenseName +
                      ' + ' +
                      x?.lenses?.lens_type_color_id?.color_name;
                  }
                  if (x?.lenses?.material_type_id) {
                    product.lenseName =
                      product.lenseName +
                      ' + ' +
                      x?.lenses?.material_type_id?.lens_material_type;
                    product.price =
                      product.price + x?.lenses?.material_type_price;
                  }
                } else if (Object.hasOwnProperty.call(x, 'accessory_image')) {
                  product.image = x.accessory_image;
                  product.name = x?.accessory_name;
                } else if (x?.lenses?.is_use_my_frame) {
                  product.name = x?.lenses?.use_my_frame_id?.frame_name;
                  product.image = x?.lenses?.use_my_frame_id?.frame_image?.[0];
                  if (x?.lenses?.lense_id) {
                    product.lenseName =
                      product.lenseName +
                      x?.lenses?.lense_id?.prescription_type;
                    product.price = product.price + x?.lenses?.lense_id?.price;
                  }
                  if (x?.lenses?.singlevision_id) {
                    product.lenseName =
                      product.lenseName +
                      ' + ' +
                      x?.lenses?.singlevision_id?.single_vision_type;
                    product.price =
                      product.price + x?.lenses?.singlevision_id?.price;
                  }
                  if (x?.lenses?.lense_type_id) {
                    product.lenseName =
                      product.lenseName +
                      ' + ' +
                      x?.lenses?.lense_type_id?.lenses_type;
                    product.price =
                      product.price + x?.lenses?.lense_type_id?.price;
                  }
                  if (x?.lenses?.lens_type_color_id) {
                    product.lenseName =
                      product.lenseName +
                      ' + ' +
                      x?.lenses?.lens_type_color_id?.color_name;
                  }
                  if (x?.lenses?.material_type_id) {
                    product.lenseName =
                      product.lenseName +
                      ' + ' +
                      x?.lenses?.material_type_id?.lens_material_type;
                    product.price =
                      product.price + x?.lenses?.material_type_id?.price;
                  }
                }
                return (
                  <div
                    className="my_order_main_details_status_order_details"
                    key={index}
                  >
                    <img
                      loading="lazy"
                      src={product.image}
                      className="my_order_main_details_status_order_image w-[98px] h-[82px] object-contain"
                      alt=""
                    />
                    <div className="my_order_main_details_status_order_details_data">
                      <styled.Para20Tag
                        color="#2E3233"
                        $weight="600"
                        className="capitalize"
                      >
                        {product.name ? product.name : product.lenseName}
                      </styled.Para20Tag>
                      {product.lenseName && (
                        <styled.Para16Tag color="#2E3233" $weight="500">
                          {`Lens: ${product.lenseName}`}
                        </styled.Para16Tag>
                      )}
                      {product.color && (
                        <styled.Para16Tag color="#2E3233" $weight="500">
                          {`Color: ${product.color}`}
                        </styled.Para16Tag>
                      )}
                      {product.size && (
                        <styled.Para16Tag color="#2E3233" $weight="500">
                          {`Size: ${product.size}`}
                        </styled.Para16Tag>
                      )}
                      {product.model && (
                        <styled.Para16Tag color="#2E3233" weight="500">
                          {`Model: ${product.model}`}
                        </styled.Para16Tag>
                      )}
                      {product.sku && (
                        <styled.Para16Tag color="#2E3233" $weight="500">
                          {`Sku: ${product.sku}`}
                        </styled.Para16Tag>
                      )}
                      <styled.Para16Tag color="#2E3233" $weight="500">
                        {`Price: $${product.price}`}
                      </styled.Para16Tag>
                      <styled.Para16Tag color="#219EBC" $weight="400">
                        Sold by Optics Haven
                      </styled.Para16Tag>
                    </div>
                  </div>
                );
              })}

              <styled.BorderDivider $m_bottom={20} m_top={20} />
              <div className="my_order_main_details_status_amount">
                <div className="my_order_main_details_status_total_amount">
                  <styled.Para12Tag>Total Amount</styled.Para12Tag>
                  <div className="my_order_main_details_status_total_amount_head">
                    <styled.Head24Tag>${item?.total_price}</styled.Head24Tag>
                  </div>
                </div>

                <div className="flex items-center flex-wrap gap-4">
                  <PDFDownloadLink
                    document={<InVoicePdf item={item} />}
                    fileName="optics_haven_invoice.pdf"
                    className="mobile:w-full"
                  >
                    {({ loading }) => (
                      <styled.ButtonWrapper
                        $bg_color={'#FFF'}
                        $border_color={'#DEE2E4'}
                        color={'#5F6364'}
                        $hover_color={'#2E3233'}
                        $hover_bg={'#FFF'}
                        $hover_border={'#DEE2E4'}
                      >
                        {loading ? 'Loading document...' : 'Download Invoice'}
                      </styled.ButtonWrapper>
                    )}
                  </PDFDownloadLink>
                  {item?.items?.[0]?.lenses?.is_use_my_frame ? (
                    <styled.LinkWrapper
                      $bg_color={'#FFF'}
                      $bColor={'#DEE2E4'}
                      color={'#5F6364'}
                      $hover_color={'#2E3233'}
                      $hover_bg={'#FFF'}
                      $hover_border={'#DEE2E4'}
                      to={
                        'https://fulfillment.fedex.com/web/commerce/rma-web?customerId=2558151&token=eyJjdHkiOiJKV1QiLCJlbmMiOiJBMTI4Q0JDLUhTMjU2IiwiYWxnIjoiZGlyIn0..FsmqOK6l_gtsqrlAHcwxXA.Dwmz_O0-ORLXlBafxmrAjYXNh1oYG35bDkuXrZ-xV_SMo0_eXW_4cHLZktpbxBVf3tr59UIhmqakkb63iR7TSihkosxIDCWErBfEpB4Pb2hXhvN4ym1NC3XRbmCk-0d1lIQhWBRnMAd8A1Aw5tK7xj4yogmEw4BAPV5JpEoS278KHi4ypK-7iO6pmzxyAKcAx9fm6edceAflU4LByj-_D_asL1i7ACL7fu1qQOjPncqFDjyjUU7G5WZCMZtZeIlJ63xX6QPlSDG0OVO_x7QAuzZnxeBX4_2A9XjkJh-f36s3-t4-tb9ln3lLvXlF7IrF0pDTpe6P72rc2DQzIznsaAHZbAqpxxLL2IjyaoS3G9vnL8dxLzNHImyLvn8DO766FlBg6pv8-aYbAy9v4XAUKQ.WqAnQD3ko2Iq2CGRJx6pXQ'
                      }
                    >
                      Shipping Label
                    </styled.LinkWrapper>
                  ) : null}
                  <styled.LinkWrapper
                    to={`${routesConfig?.orderDetails}/${item?._id}`}
                  >
                    View Details
                  </styled.LinkWrapper>
                </div>
              </div>
            </div>
          </styled.DashboardDetailsBorder>
        </li>
      </>
    );
  });

  return {
    orderStatus,
    orderStatusData,
    orderStatusValue,
    orderStatusList,
    isLoading,
    orderFilterData,
  };
};

export default useOrderData;
