import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Svg,
  Path,
} from '@react-pdf/renderer';
import moment from 'moment';

const Br = () => '\n';
// Font.register({
//   family: 'Inter',
//   fonts: [
//     {
//       src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf',
//       fontWeight: 100,
//     },
//     {
//       src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf',
//       fontWeight: 200,
//     },
//     {
//       src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
//       fontWeight: 300,
//     },
//     {
//       src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
//       fontWeight: 400,
//     },
//     {
//       src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
//       fontWeight: 500,
//     },
//     {
//       src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
//       fontWeight: 600,
//     },
//     {
//       src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
//       fontWeight: 700,
//     },
//     {
//       src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf',
//       fontWeight: 800,
//     },
//     {
//       src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf',
//       fontWeight: 900,
//     },
//   ],
// });

const styles = StyleSheet.create({
  container: {
    margin: '32px',
    // fontFamily: 'Inter',
    color: '#3c3b3a',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    // alignItems: 'center',
    marginBottom: '12px',
    flexDirection: 'row',
  },
  totalWrapper: {
    marginTop: '32px',
    marginBottom: '32px',
    display: 'flex',
    flexDirection: 'column',
  },
  total: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginBottom: '10px',
    backgroundColor: '#e3dbd4',
    paddingTop: '8px',
    paddingBottom: '8px',
    fontSize: '12px',
    paddingRight: '35px',
    fontWeight: 'bold',
    gap: '20px',
  },
  totalDiscount: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '20px',
    flexDirection: 'row',
    paddingTop: '8px',
    paddingBottom: '8px',
    fontSize: '12px',
    paddingRight: '35px',
  },
  onlyFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  invoice: {
    fontSize: '32px',
  },
  marginB: {
    marginBottom: '32px',
  },
  maxWidth: {
    maxWidth: '200px',
  },
  flexCol: {
    display: 'flex',
    marginBottom: '12px',
    flexDirection: 'column',
  },
  page: {
    padding: 20,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    alignItems: 'center',
    fontSize: 10,
    width: '100%',
    display: 'flex',
  },
  tableCell: {
    borderRightWidth: 1,
    borderRightColor: '#000',
    paddingRight: 5,
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    // height: '100% !important',
    height: '100%',
    textAlign: 'center',
    // backgroundColor: '#219EBC'
    // height: '6rem',
  },
  tableHeadCell: {
    width: '16%',
    padding: '2px',
    paddingTop: '8px',
    paddingBottom: '8px',
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableHeadCellDetail: {
    width: '16%',
    padding: '2px',
    paddingTop: '14px',
    paddingBottom: '8px',
    fontSize: '12px',
    fontWeight: 'normal',
    textAlign: 'center',
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#e3dbd4',
  },
  shippingFont: {
    fontWeight: 'bold',
  },
  issuesTo: {
    marginTop: '24px',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  shippingDetail: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  issuesAddress: {
    fontSize: '12px',
    fontWeight: 'normal',
    marginTop: '8px',
  },
  shippingAddress: {
    fontSize: '12px',
    fontWeight: 'normal',
    marginTop: '5px',
    color: '#3c3b3a',
    // width: '12rem'
  },
  shippingAddress2: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
    fontWeight: 'normal',
    // marginTop: '5px',
    color: '#3c3b3a',
  },
  shippingAddress3: {
    display: 'flex',
    flexDirection: 'column',
  },
  shippedAdd: {
    fontSize: '12px',
    fontWeight: 'normal',
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'row',
  },
  opticLogo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '40px',
  },
  tableRowMain: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  shippingName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  shippaddressflex: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    // flexWrap: 'wrap'
  },
  OpticsTitle: {
    fontSize: '12px',
    fontWeight: 'normal',
    marginTop: '5px',
    color: '#3c3b3a',
    letterSpacing: '2px',
  },
  OpticsSubTitle: {
    fontSize: '10px',
    fontWeight: 'normal',
    marginTop: '3px',
    color: '#3c3b3a',
    letterSpacing: '1px',
  },
});

const InVoicePdf = ({ item }) => {
  const orderDate = moment(item?.created_at).format('DD MMMM YYYY');

  // const name =
  //   item?.user_id?.firstname && item?.user_id?.lastname
  //     ? item?.user_id?.firstname + ' ' + item?.user_id?.lastname
  //     : item?.user_id?.username;

  return (
    <>
      <Document>
        <Page size="A4">
          <View style={styles.container}>
            <View style={styles.opticLogo}>
              <View>
                <Svg
                  width="110"
                  height="50"
                  viewBox="0 0 110 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/Svg"
                >
                  <Path
                    d="M25.7812 2.65625C32.6979 2.65625 38.2917 4.76042 42.5625 8.96875C46.8542 13.1771 49 18.5833 49 25.1875C49 29.625 48.0208 33.5625 46.0625 37C44.1042 40.4375 41.3958 43.0938 37.9375 44.9688C34.5 46.8229 30.3438 47.75 25.4688 47.75C19.0312 47.75 13.7604 45.9792 9.65625 42.4375C4.69792 38.1667 2.21875 32.4583 2.21875 25.3125C2.21875 18.6667 4.38542 13.2292 8.71875 9C13.0729 4.77083 18.7604 2.65625 25.7812 2.65625ZM25.9062 9.59375C22.5938 9.59375 19.9583 10.8333 18 13.3125C15.5417 16.4375 14.3125 20.4688 14.3125 25.4062C14.3125 30.3438 15.4583 34.25 17.75 37.125C19.7292 39.5833 22.3438 40.8125 25.5938 40.8125C28.8646 40.8125 31.4792 39.5833 33.4375 37.125C35.75 34.1875 36.9062 30.2604 36.9062 25.3438C36.9062 21.8854 36.4062 18.9792 35.4062 16.625C34.4271 14.2708 33.0938 12.5104 31.4062 11.3438C29.7188 10.1771 27.8854 9.59375 25.9062 9.59375Z"
                    fill="#219EBC"
                  />
                  <Path
                    d="M51.2812 3.40625H72.5625V9.4375H70.5938C69.5104 9.4375 68.7708 9.55208 68.375 9.78125C67.9792 9.98958 67.6979 10.3021 67.5312 10.7188C67.3646 11.1146 67.2812 11.9896 67.2812 13.3438V20.9375H83.1562V12.7812C83.1562 11.7812 83.0938 11.1042 82.9688 10.75C82.8021 10.25 82.5208 9.90625 82.125 9.71875C81.7292 9.53125 80.8542 9.4375 79.5 9.4375H77.8438V3.40625H99.1875V9.4375H97.4062C96.1979 9.4375 95.3958 9.54167 95 9.75C94.6042 9.9375 94.3229 10.2292 94.1562 10.625C94.0104 11 93.9375 11.9062 93.9375 13.3438V36.9688C93.9375 38.4271 94.0312 39.375 94.2188 39.8125C94.4271 40.2292 94.7292 40.5312 95.125 40.7188C95.5417 40.8854 96.4792 40.9688 97.9375 40.9688H99.1875V47H77.8438V40.9688H79.25C80.5625 40.9688 81.4479 40.8646 81.9062 40.6562C82.3854 40.4479 82.7083 40.1458 82.875 39.75C83.0625 39.3542 83.1562 38.4271 83.1562 36.9688V27.75H67.2812V36.9688C67.2812 38.4271 67.3438 39.3333 67.4688 39.6875C67.6771 40.2083 68 40.5521 68.4375 40.7188C68.875 40.8854 69.7604 40.9688 71.0938 40.9688H72.5625V47H51.2812V40.9688H52.7188C53.9271 40.9688 54.7708 40.8646 55.25 40.6562C55.7292 40.4479 56.0521 40.1562 56.2188 39.7812C56.4062 39.4062 56.5 38.5833 56.5 37.3125V13.625C56.5 12.1458 56.4062 11.1875 56.2188 10.75C56.0521 10.2917 55.7812 9.95833 55.4062 9.75C55.0312 9.54167 54.3021 9.4375 53.2188 9.4375H51.2812V3.40625Z"
                    fill="#219EBC"
                  />
                </Svg>
              </View>
              <Text style={styles.OpticsTitle}>OPTICS HAVEN</Text>
              <Text style={styles.OpticsSubTitle}>See and Be Seen</Text>
            </View>
            <View style={styles.flex}>
              <View style={styles.flexCol}>
                <Text style={styles.issuesTo}>ISSUES TO:</Text>
                <View style={styles.maxWidth}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                    }}
                  >
                    <Text style={styles.shippingAddress}>
                      Name:{' '}
                      {`${item?.shipping_address_data?.firstname} ${item?.shipping_address_data?.lastname}`}
                    </Text>

                    <View style={styles.shippingAddress2}>
                      <Text style={styles.shippingAddress3}>
                        <Text>{`Address: `}</Text>
                      </Text>
                      <Text style={styles.shippingAddress3}>
                        <Text>
                          {`${item?.shipping_address_data?.address}`},
                        </Text>
                        <Br />
                        <Text>{`${item?.shipping_address_data?.city}`},</Text>
                        <Br />
                        <Text>{`${item?.shipping_address_data?.state}`},</Text>
                        <Text>{` ${item?.shipping_address_data?.zipcode}`}</Text>
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                {/* <Text style={styles.invoice}>{`Invoice`}</Text> */}
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '200px',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text style={{ ...styles.issuesTo }}>INVOICE NO: </Text>
                  <Text style={{ ...styles.issuesAddress }}>Date : </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '200px',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text style={{ ...styles.issuesTo }}>
                    {' '}
                    {`${item?.sequence_no}`}
                  </Text>
                  <Text style={{ ...styles.issuesAddress }}> {orderDate}</Text>
                </View>
              </View>
            </View>

            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '32px',
                // borderWidth: 1,
                // borderColor: '#000',
              }}
            >
              <View style={styles.tableHeader}>
                <Text
                  style={{
                    width: '50%',
                    padding: '2px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    paddingLeft: '20px',
                  }}
                >
                  FRAME NAME AND LENS
                </Text>
                <Text style={styles.tableHeadCell}>UNIT PRICE</Text>
                <Text style={styles.tableHeadCell}>QTY</Text>
                <Text style={styles.tableHeadCell}>TOTAL</Text>
              </View>
              {/* Table Rows */}
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {item?.items?.map((x, index) => {
                  let product = {
                    name: '',
                    lenseDetails: [],
                    image: '',
                    color: '',
                    size: '',
                    model: '',
                    sku: '',
                    price: x?.product_price || x?.accessory_price || 0,
                    frame_price: 0,
                    lens_price: 0,
                  };

                  if (Object.hasOwnProperty.call(x, 'product_image')) {
                    product.image = x?.product_image;
                    product.name = x?.product_name;
                    product.size = x?.product_size_name;
                    product.color = x?.product_color;
                    product.sku = x?.product_sku_no;
                    product.frame_price = x?.product_price;

                    // Build product.lenseDetails array
                    if (x?.lenses?.lense_id) {
                      product.lenseDetails.push({
                        type: `${x?.lenses?.lense_id?.prescription_type}`,
                        price: x?.lenses?.lens_price,
                      });
                      product.price += x?.lenses?.lens_price;
                    }

                    if (x?.lenses?.singlevision_id) {
                      product.lenseDetails.push({
                        type: `${x?.lenses?.singlevision_id?.single_vision_type}`,
                        price: x?.lenses?.singlevision_price,
                      });
                      product.price += x?.lenses?.singlevision_price;
                    }
                    if (x?.lenses?.lense_type_id) {
                      product.lenseDetails.push({
                        type: `${x?.lenses?.lense_type_id?.lenses_type}`,
                        price: x?.lenses?.lens_type_price,
                      });
                      product.price += x?.lenses?.lens_type_price;
                    }
                    if (x?.lenses?.lens_type_color_id) {
                      product.lenseDetails.push({
                        type: `${x?.lenses?.lens_type_color_id?.color_name}`,
                        price: 0,
                      });
                    }
                    if (x?.lenses?.material_type_id) {
                      product.lenseDetails.push({
                        type: `${x?.lenses?.material_type_id?.lens_material_type}`,
                        price: x?.lenses?.material_type_price,
                      });
                      product.price += x?.lenses?.material_type_price;
                    }
                  } else if (Object.hasOwnProperty.call(x, 'accessory_image')) {
                    product.image = x.accessory_image;
                    product.name = x?.accessory_name;
                  } else if (x?.lenses?.is_use_my_frame) {
                    product.name = x?.lenses?.use_my_frame_id?.frame_name;
                    product.image =
                      x?.lenses?.use_my_frame_id?.frame_image?.[0];

                    // Build product.lenseDetails array for user's own frame
                    if (x?.lenses?.lense_id) {
                      product.lenseDetails.push({
                        type: `${x?.lenses?.lense_id?.prescription_type}`,
                        price: x?.lenses?.lense_id?.price,
                      });
                      product.price += x?.lenses?.lense_id?.price;
                    }

                    if (x?.lenses?.singlevision_id) {
                      product.lenseDetails.push({
                        type: `${x?.lenses?.singlevision_id?.single_vision_type}`,
                        price: x?.lenses?.singlevision_id?.price,
                      });
                      product.price += x?.lenses?.singlevision_id?.price;
                    }

                    if (x?.lenses?.lense_type_id) {
                      product.lenseDetails.push({
                        type: `${x?.lenses?.lense_type_id?.lenses_type}`,
                        price: x?.lenses?.lense_type_id?.price,
                      });
                      product.price += x?.lenses?.lense_type_id?.price;
                    }

                    if (x?.lenses?.lens_type_color_id) {
                      product.lenseDetails.push({
                        type: `${x?.lenses?.lens_type_color_id?.color_name}`,
                        price: 0,
                      });
                    }

                    if (x?.lenses?.material_type_id) {
                      product.lenseDetails.push({
                        type: `${x?.lenses?.material_type_id?.lens_material_type}`,
                        price: x?.lenses?.material_type_id?.price,
                      });
                      product.price += x?.lenses?.material_type_id?.price;
                    }
                  }
                  return (
                    <>
                      {index > 0 && (
                        <View
                          style={{
                            borderBottomWidth: 1,
                            borderBottomColor: 'black',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }}
                        />
                      )}
                      <View key={index} style={styles.tableRowMain}>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            paddingTop: '14px',
                            gap: '14px',
                            alignItems: 'start',
                            fontSize: '12px',
                          }}
                        >
                          <Text style={{ paddingLeft: '20px' }}>
                            {' '}
                            {`${
                              product.name ? product.name : product.lenseName
                            }${product.color && `, (${product.color}),`}${
                              product.size && ` ${product.size},`
                            }${product.model && ` ${product.model},`}${
                              product.sku && ` ${product.sku}`
                            }`}
                          </Text>
                          {product.lenseDetails.map((lens, lensIndex) => (
                            <Text
                              key={lensIndex}
                              style={{ marginLeft: '20px' }}
                            >
                              {lens.type}
                            </Text>
                          ))}
                        </View>

                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '16%',
                            textAlign: 'center',
                            padding: '2px',
                            paddingTop: '14px',
                            gap: '14px',
                            fontSize: '12px',
                          }}
                        >
                          <Text
                            style={{ textAlign: 'center' }}
                          >{`$${product.frame_price}`}</Text>
                          {product.lenseDetails.map((lens, lensIndex) => (
                            <Text
                              key={lensIndex}
                              style={{ textAlign: 'center' }}
                            >
                              {`$${lens.price}`}
                            </Text>
                          ))}
                        </View>
                        <View style={styles.tableHeadCellDetail}>
                          <Text style={{ textAlign: 'center' }}>
                            {x.quantity}
                          </Text>
                        </View>

                        <Text
                          style={styles.tableHeadCellDetail}
                        >{`$${product.price}`}</Text>
                      </View>
                    </>
                  );
                })}
              </View>
            </View>

            <View style={styles.totalWrapper}>
              {/* <View style={styles.total}>
                <Text>Item Price: </Text>
                <Text style={{ textAlign: 'end' }}>{`$${item?.price}`}</Text>
              </View> */}
              {item?.total_promocode_price > 0 && (
                <View style={styles.totalDiscount}>
                  <Text>Total Offer Discount </Text>
                  <Text>{`$${item?.total_promocode_price}`}</Text>
                </View>
              )}
              {/* <View style={styles.totalDiscount}>
                <Text>Total Offer Discount </Text>
                <Text >{`$${item?.total_promocode_price ? item?.total_promocode_price : 0
                  }`}</Text>
              </View> */}
              <View style={styles.total}>
                <Text>TOTAL: </Text>
                <Text>${item?.total_price}</Text>
              </View>
            </View>

            {/* <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}> */}
            {/* <View>
                <Text style={styles.issuesTo}>SHIPPING ADDRESS:</Text>
                <View style={{ marginTop: '10px' }}>
                  <View style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <Text style={styles.shippingAddress}>
                      Name:{' '} {`${item?.shipping_address_data?.firstname} ${item?.shipping_address_data?.lastname}`}
                    </Text>

                    <View style={styles.shippingAddress2}>
                      <Text style={styles.shippingAddress3}>
                        <Text>{`Address: `}</Text>
                      </Text>
                      <Text style={styles.shippingAddress3}>
                        <Text>{`${item?.shipping_address_data?.address}`},</Text><Br />
                        <Text>{`${item?.shipping_address_data?.city}`},</Text><Br />
                        <Text>{`${item?.shipping_address_data?.state}`},</Text>
                        <Text>{` ${item?.shipping_address_data?.zipcode}`}</Text>
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.shippingAddress}>
                        Mob No. {item?.shipping_address_data?.mobile_no}
                      </Text>
                    </View>
                  </View>
                </View>
              </View> */}

            <View
              style={{
                ...styles.issuesTo,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Text>OPTICS HAVEN</Text>
              <Text style={styles.maxWidth}>
                1305 WEST 7 TH ST SUITE. 1 - 1063 FREDERICK, MD 21702
              </Text>
            </View>
          </View>
          {/* </View> */}
        </Page>
      </Document>
    </>
  );
};

export default InVoicePdf;
