/**
 * The above code is a JavaScript function that creates and lists reviews using Axios API calls.
 * @param data - The `data` parameter is an object that contains the necessary information for creating
 * or listing reviews. It may include properties such as the review content, user information, product
 * information, etc. The specific properties and their values depend on the requirements of your
 * application.
 */
import * as constants from 'redux/constants';
import * as API from 'helper/API';
import { ErrorToast } from 'helper/Toast';
import { message } from 'helper/config/message.config';

export const createReview = (data, showSnackbar) => async (dispatch) => {
  try {
    const headers = await API.getFormDataHeaders();

    const formData = new FormData();
    formData.append('comment', data.comment);
    formData.append('product_frame_id', data.product_frame_id);
    formData.append('rating', data.rating);
    formData.append('user_id', data.user_id);

    const response = await API.ReviewAPI.post(`/`, formData, { headers });

    dispatch({
      type: constants.ADD_REVIEW_SUCCESS,
      payload: response?.data,
    });

    if (data?.message?.status === 200) {
      showSnackbar(message?.ADD_SUCCESS_REVIEW, 'soft', 'success');
    }
  } catch (error) {
    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === 'Network Error')
      ErrorToast(message?.NETWORK_ERROR);
    else throw error;
  }
};

export const listReviews = (data) => async (dispatch) => {
  try {
    dispatch({ type: constants.LIST_ALL_REVIEW_REQUEST });

    // AXIOS BODY
    const body = data;

    // API
    const response = await API.CommonApi.post(
      `/product-review/user/product`,
      body,
      API.APIHeaderCors
    );

    dispatch({
      type: constants.LIST_ALL_REVIEW_SUCCESS,
      payload: {
        reviewData: response?.data?.data,
        reviewAvg: response?.data?.avg,
      },
    });
  } catch (error) {
    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === 'Network Error')
      ErrorToast(message?.NETWORK_ERROR);
    else throw error;
  }
};

export const postReviewLikeDislike =
  (review_id, action) => async (dispatch) => {
    try {
      const headers = await API.getHeaders();

      // AXIOS BODY
      const body = {
        action: action,
      };

      const response = await API.ReviewAPI.post(
        `/like-dislike/${review_id}`,
        body,
        {
          headers,
        }
      );
      dispatch({
        type: constants.POST_REVIEW_LIKE_DISLIKE_REQUEST,
        payload: response?.data,
      });
    } catch (error) {
      if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
      else if (error?.message === 'Network Error')
        ErrorToast(message?.NETWORK_ERROR);
      else throw error;
    }
  };

//   export const getReview = () => async (dispatch) => {
//    try {
//     dispatch({ type: constants.LIST_ALL_REVIEW_REQUEST });

//     // API
//     const response = await API.CommonApi.get(
//       `/reviews`,
//     );
//     console.log('API Response:', response?.data);
//     dispatch({
//       type: constants.LIST_ALL_REVIEW_SUCCESS,
//       payload: {
//         reviewData: response?.data,
//         // reviewAvg: response?.data?.avg,
//       },
//     });

//   } catch (error) {
//     if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
//     else if (error?.message === 'Network Error')
//       ErrorToast(message?.NETWORK_ERROR);
//     else throw error;
//   }
// };
export const getReview = () => async (dispatch) => {
  try {
    // Dispatch request action to set loading state
    dispatch({ type: constants.LIST_ALL_REVIEW_REQUEST });

    // Fetch data from the API
    const response = await API.CommonApi.get(`/reviews`);
    console.log('API Response:', response?.data);

    // Dispatch success action with the received data
    dispatch({
      type: constants.LIST_ALL_REVIEW_SUCCESS,
      payload: {
        reviewData: response?.data, // Pass the data to reducer
      },
    });
  } catch (error) {
    // Handle errors with appropriate messaging
    if (error.message === 'Network Error') {
      ErrorToast(message.NETWORK_ERROR);
    } else {
      ErrorToast(message.ERROR_TRY_AGAIN);
    }

    // Dispatch failure action to update the state
    dispatch({
      type: constants.LIST_ALL_REVIEW_FAILURE,
      payload: {
        error: error.message,
      },
    });
  }
};
