import React from 'react';
import * as styles from 'assets/styles';

const CartLensDetails = (props) => {
  //v2
  const lenses = props.item?.lenses || {};
  console.log('🚀 ~ file: CartLensDetails.jsx:9 ~ lenses:', lenses);

  const lenseid = 'lense_id' in lenses;
  console.log('🚀 ~ file: CartLensDetails.jsx:11 ~ lenseid:', lenseid);
  const singlevisionid = 'singlevision_id' in lenses;
  const lensetypeid = 'lense_type_id' in lenses;
  const materialtypeid = 'material_type_id' in lenses;

  return (
    <>
      {lenseid && singlevisionid && lensetypeid && materialtypeid == true ? (
        <>
          {props.item?.lens_id_price === undefined ? (
            ''
          ) : (
            <styles.Para18Tag>{`Lens details :`}</styles.Para18Tag>
          )}

          <div className="w-full flex flex-col gap-1">
            {props.item?.lens_id_price === undefined ? (
              ''
            ) : (
              <div className="flex gap-8 justify-between">
                <styles.Para16Tag color={'#2E3233'} $weight={'500'}>
                  {props.item.lens_id}
                </styles.Para16Tag>
                <styles.Para16Tag color={'#2E3233'} $weight={'500'}>
                  (
                  {console.log(
                    '🚀 ~ file: CartLensDetails.jsx:40 ~ props.item?.lens_id_price:',
                    props.item?.lens_id_price
                  )}
                  {props.item?.lens_id_price === 0
                    ? 'free'
                    : `$${props.item?.lens_id_price}`}
                  )
                </styles.Para16Tag>
              </div>
            )}

            {props.item?.singlevision_id_price === undefined ? (
              ''
            ) : (
              <>
                {props.item.singlevision_id && (
                  <div className="flex gap-8 justify-between">
                    <styles.Para16Tag color={'#2E3233'} $weight={'500'}>
                      {props.item.singlevision_id}
                    </styles.Para16Tag>
                    <styles.Para16Tag color={'#2E3233'} $weight={'500'}>
                      (
                      {props.item?.singlevision_id_price === 0
                        ? 'free'
                        : `$${props.item?.singlevision_id_price}`}
                      )
                    </styles.Para16Tag>
                  </div>
                )}
              </>
            )}

            {props.item?.lense_type_price === undefined ? (
              ''
            ) : (
              <div className="flex gap-8 justify-between">
                <styles.Para16Tag color={'#2E3233'} $weight={'500'}>
                  {props.item?.lense_type}
                </styles.Para16Tag>
                <styles.Para16Tag color={'#2E3233'} $weight={'500'}>
                  (
                  {props.item?.lense_type_price === 0
                    ? 'free'
                    : `$${props.item?.lense_type_price}`}
                  )
                </styles.Para16Tag>
              </div>
            )}

            {props.item?.lenses?.color_id?.color_name ? (
              <styles.Para16Tag color={'#2E3233'} $weight={'500'}>
                {props.item?.lenses?.color_id?.color_name}
              </styles.Para16Tag>
            ) : (
              ''
            )}

            {props.item?.lense_material_price === undefined ? (
              ''
            ) : (
              <div className="flex gap-8 justify-between">
                <styles.Para16Tag color={'#2E3233'} $weight={'500'}>
                  {props.item.lense_material}
                </styles.Para16Tag>
                <styles.Para16Tag color={'#2E3233'} $weight={'500'}>
                  (
                  {props.item?.lense_material_price === 0
                    ? 'free'
                    : `$${props.item?.lense_material_price}`}
                  )
                </styles.Para16Tag>
              </div>
            )}
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default CartLensDetails;
